'use client'

import {motion,AnimatePresence} from 'framer-motion'
export const div=motion.div;
export const h1=motion.h1;
export const h2=motion.h2;
export const h3=motion.h3;
export const span=motion.span;
export const p=motion.p;
export const button=motion.button;
export const Anime=AnimatePresence;